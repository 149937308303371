// const jQuery = require('jquery');
// const $ = require('jquery');



// // import './jssocials-1.4.0/jssocials-theme-classic.css';
// // import '';

// var jssocials = require('./jssocials-1.4.0/jssocials.min');


// $(document).ready(function(){

// 	alert("a");

// 	$("#share").jsSocials({
// 	    shares: ["email", "twitter", "facebook", "googleplus", "linkedin", "pinterest", "stumbleupon", "whatsapp"]
// 	});

// })

